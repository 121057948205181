@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.cardItem {
    flex: 1 0 11%;
    padding: 5px;
    overflow: hidden;
}

.verticalCardItem.cardItem {
    min-width: 110px;
    max-width: 178px;
    flex-basis: auto;
    flex-grow: 1;
}

.cardContent {
    padding: 7px !important;
}

.sectionTopBar h2 {
    font-weight: 200;
}

.sectionTopBar h2:first-letter {
    text-transform: uppercase;
}

.cardItem .cardTitle {
    position: relative;
    white-space: pre;
    overflow: hidden;
    font-size: 1.2rem;
}

.cardItem .productPrice {
    float: right;    
    font-weight: 500;
    margin-top: -15px;
    text-align: right;
    position: relative;
}

.cardItem .productPrice .discount {
    color: #000000;
    position: relative;
    font-size: 0.9rem;
}
.cardItem .productPrice .price {
    color: #299781;
    position: relative;
    font-size: 0.9rem;
}

.discount::after {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    -webkit-transform:rotate(-7deg);
    -moz-transform:rotate(-7deg);
    -ms-transform:rotate(-7deg);
    -o-transform:rotate(-7deg);
    transform:rotate(-7deg);
}
.cardItem .productPrice .discount > span {
    color: #525672;    
}

button .discount {
    position: relative;
    color: #525672;
}

button .discount > span {
    font-size: .9rem;
    color: #d7e6f3;
}

.cardItem .cardDesc {
    overflow: hidden;
    white-space: pre;
    position: relative;
}

.horizontalCardItem .cardImage {
    width: 40%;
    display: inline-block;
    vertical-align: top;
}
.horizontalCardItem > div {
    min-height: 150px;    
}
.horizontalCardItem > div {
    position: relative;
}

.horizontalCardItem .cardContent {
    width: 60%;
    display: inline-flex;
    flex-direction: column;
    line-height: 1;
    padding-bottom: 0 !important;
    position: absolute;
    height: 100%;
    padding: 3%;
}

.horizontalCardItem .cardActions{
    bottom: 0;
    width: 94%;
    position: absolute;
    padding: 18px 0 8px 0;
    background-color: #fff;
}

.verticalCardItem .cardContent {
    padding-bottom: 5px !important;
}

.horizontalCardItem .cardDesc {
    white-space: normal;
    margin-top: 5px;
    max-height: 50%;
}

@media (max-width: 600px) {
    .verticalCardItem.cardItem {
        max-width: 170px;
    }
}

@media (max-width: 562px) {
    .verticalCardItem.cardItem {
        max-width: 140px;
    }
}

@media (max-width: 400px) {
    .productSection .cardItem:nth-child(3) {
        display: none;
    }
}