:root {
  --splash-header-bg: #1b3f5e;
  --splash-navbar-bg: #1b3f5e;
  --splash-dark-text: #1b3f5e;
  --splash-light-text: #3b99f0;
}
body {
  background-color: #fff;
}
.text-blue {
    color: #1b3f5e;
}
/* Custom Font Class*/
.gotham-bold {
  font-family: Gotham Rounded;
  font-style: normal;
  font-weight: bold;
}
.avenir-normal {
  font-family: Avenir;
  font-style: normal;
}

#language-menu {
  margin-top: 40px;
  z-index: 999999999;
}

/* Splash*/
.splash-section {
  padding-top: 80px;
}
.splash-header > div .links a.btn-login,
.splash-header .splash-navigation-tools a.btn-login {
  color: #4261ef;
  background: #eff5ff;
  padding: 10px 22px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500 !important;
  margin-left: 34px !important;
}
.splash-header .lang-switcher-btn {
  margin-left: 18px !important;
  color: #fff;
}
.splash-header > div .links a.btn-signup {
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc/BZabc-signup-shape.png);
}
.splash-header a.btn-gift {
  color: #fff;
  margin-left: 30px;
  padding: 6px 15px;
  border-radius: 19px;
  font-family: Gotham Rounded;
  font-style: normal;
  font-weight: 350 !important;
  font-size: 16px;
  text-decoration: none;
}
.splash-header a.btn-gift img {
  height: 16px;
  margin-right: 5px;
}
.splash-header a.btn-gift span {
  vertical-align: middle;
}
.splash-header a.btn-gift i {
  font-size: 24px;
  margin-right: 10px;
  margin-top: -5px;
  vertical-align: middle;
}
.splash-header > div .links a,
.splash-footer > div .links a {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  margin-left: 32px;
  font-family: Gotham Rounded;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  padding: 6px 8px;
}

.splash-header > div .links a.active {
  color: #4261ef;
  background: #deebfe;
  border-radius: 29px;
}

.splash-footer > div .links a {
  color: #fff;
}

.download-tool {
  background-color: #1b3f5f;
  border-radius: 5px;
}
.download-tool h4 {
  color: #4788cc;
}
.splash-header {
  height: 85px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
}
.header-drawer {
  z-index: 99999999 !important;
}
.header-drawer:nth-child(1) {
  background: #eff5ff !important;
}
.header-drawer a,
.header-drawer a:hover {
  text-decoration: none;
}
.header-drawer span,
.header-drawer i {
  color: #4261ef !important;
}
.header-drawer i {
  width: 1em;
  height: 1em;
  font-size: 21px;
  margin-left: 4px;
}
.header-drawer .drawer-text span {
  font-size: 15px;
  font-weight: 400;
}
.drawer-menu-btn {
  margin-right: 20px;
  padding: 10px 18px;
  background: #eff5ff;
  color: #4261ef;
  border-radius: 4px;
  cursor: pointer;
}
.drawer-menu-btn i {
  font-size: 20px;
}

.main-banner {
  color: var(--splash-light-text);
  background-color: #f2f7fa;
  font-family: Gotham Rounded;
}
.main-banner .main-banner-background {
  background-image: url("https://gravitybrainpublic.s3.amazonaws.com/image/gravitybrain/splashHue_0000s_0003_test2.jpg");
  background-size: 100% auto;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-color: #f4f8fb;
  padding-top: 300px;
  padding-bottom: 50px;
}

.main-banner .main-banner-background-schools {
  background-size: auto 100%;
  background-position-x: 90%;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-color: #f4f8fb;
  padding-top: 50px;
  padding-bottom: 50px;
}

.main-banner h1,
.bzabc-application h1 {
  font-weight: bold;
  font-size: 42px;
  line-height: 130%;
  font-family: Gotham Rounded;
  white-space: pre-wrap;
}
.main-banner h1 {
  color: #0e57c4;
}

.main-banner h3 {
  font-weight: 350;
  font-size: 18px;
  line-height: 29px;
}

.main-banner h6 {
  font-weight: 350;
  font-size: 14px;
  line-height: 17px;
  color: #2a6bcb;
}
.main-banner-welcome h1 { 
    display: inline-block;
    background-color: rgba(27, 63, 94, 0.5);
    margin: 0 auto;
}
.main-banner h6.workbooks {
  margin: auto;
  font-weight: bold;
  font-size: 20px;
  line-height: 18px;
  white-space: pre-wrap;
  color: #0e57c3;
  text-shadow: 0 0 5px #fff, 0 0 6px #fff;
}

.main-banner .jumbotron-text {
  color: #0e57c3;
}

.main-banner .jumbotron-text.multimedia {
  max-width: 600px;
  min-width: 350px;  
}

.main-banner .jumbotron-text h4 {
  margin-left: 40px;
}

.main-banner .jumbotron-text p,
.main-banner .jumbotron-text h5,
.main-banner .jumbotron-text h6 {
  margin-left: 60px;
  white-space: pre-wrap;
}

.main-banner .jumbotron-text p {
  color: #3b99f0;
}

.main-banner .jumbotron-text h6 {
  color: #0e57c3;
}

.main-banner .publisher-buttons {
  min-width: 650px;
}

.main-banner .contact-text {
  text-align: center;
  white-space: pre-wrap;
}

.main-banner button,
.bzabc-application button {
  border: none;
  cursor: pointer;
}

.main-banner .overview {
  margin: 20px auto;
  padding-bottom: 20px;
}

.main-banner .overview .reactPlayer {
  border-radius: 20px;
  overflow: hidden;
  min-width: 800px;
}

.main-banner .overview .platform-buttons {
  left: 0%;
  padding-left: 60px;
  max-width: 350px;
  min-width: 350px;
}
.main-banner .overview .subscriptions {
  text-align: center;
  max-height: 100px;
  padding-bottom: 60px;
}
.main-banner .overview .note {
  font-size: 20px;
  text-align: center;
  margin: 0 15px;
}
.main-banner .subscription-btn {
  padding: 16px 37px;
  border-radius: 4px;
  width: 279.467px;
  font-size: 30px;
  line-height: 25px;
  color: #fff;
}

.main-banner a:hover {
  text-decoration: none;
}

.main-banner .signup-btn {
  padding: 16px 37px;
  border-radius: 4px;
  width: 100%;
  max-width: 280px;
  max-height: 82px;
  font-size: 25px;
  line-height: 25px;
  color: #fff;
  margin-left: 20px;
  margin-top: 40px;
  white-space: pre-wrap;
}

.main-banner h4 {
  color: #0e57c3;
}

.main-banner .bookstore-background {
 
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc-bookstore.png);
  background-size: 90px;
  background-position: 65% 65%;
  background-repeat: no-repeat;
}

.main-banner .schools-buttons .bookstore-background {    
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc-bookstore.png);
  background-size: 90px;
  background-position-y: top;
  background-position-x: right;
  background-repeat: no-repeat;
}

.main-banner .bookstore-btn {
  padding: 6px 15px;
  background: #faa422;
  border-radius: 19px;
  color: #fff;
}

.main-banner .overview-pdf {
  background-image: url(//d2cnhr6egzpvdl.cloudfront.net/image/bzabc/overview_01.jpg);
  background-size: 200% auto;
  background-position: right center;
  background-repeat: no-repeat;
  width: 200px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: black;  
  margin-top: 25px;
  text-align: center;
  padding-top: 127px;
  max-height: 100px;
}

.main-banner .schools-buttons .overview-pdf {
  margin-top: -30px;
}

.main-banner .overview-pdf:hover {
  cursor: pointer;
}

.main-banner .overview-pdf .splash-round-btns {
  position: relative;
  bottom: 15px;
}

.main-banner .overview-pdf .splash-round-btns button {
  color: #5585ff;
  font-family: Gotham Rounded;
  font-weight: bold;
  font-size: 18px;
  line-height: 17px;
  background: #deebfe;
  border-radius: 29px;
  padding: 6px 16px;
  text-align: center;
}

.productRow {
  margin-bottom: 0px;
}

.product-card:hover {
  transform: scale(1.03);
  box-shadow: 0px 10px 20px #777;
}

.product-card {
  width: 400px;
  height: 400px;
  border: 1px solid #777;
  background-color: #fff;
  margin-top: 7px;
  margin-bottom: 40px;
  transition: 0.8s;
}

.product-card .product-image {
  background-size: 145% auto;
  background-color: #32587d;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position-x: center;
  background-position-y: top;
  width: 100%;
  height: 250px;
}

.product-card h3 {
  color: #fff;
  text-align: center;
  background-color: #1b3f5e;
}

.product-card .principal {
  height: 30px;
}

.product-card .principal .name {
  background-color: #555555;
  color: #fff;
  z-index: 1;
}

.info-block {
  color: black;
  font-size: 1.75rem;
  max-width: 800px;
  text-align: center;
  margin: auto !important;
}

.info-block h4 {
  margin-bottom: 1.5rem;
}

.info-block .contact-box {
  padding-top: 10px;
}

.info-block .contact-text {
  text-align: center;
  white-space: pre-wrap;
}

.splash-points {
  margin-top: 7rem;
}
.splash-points-tools {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
}
.splash-points-tools > .download-tool > div {
  display: flex;
  flex-direction: column;
}
.splash-points-tools > .download-tool > div button {
  outline: none;
  background: none;
  border: none;
}
.splash-points-tools > .download-tool > div button:first-child {
  margin-bottom: 1rem;
}
.splash-points-tools > .download-tool > div img {
  width: 30px;
}
.splash-points-tools > .download-tool > h4 {
  font-size: 11px;
}
.download-tool-dialog {
  padding-top: 60px;
  height: 100%;
  overflow: hidden;
}
.splash-points ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
}
.splash-points ul li {
  margin-bottom: 0.75rem;
  background-color: var(--splash-navbar-bg);
  font-size: 34px;
  color: #fff;
  text-align: left;
  padding: 0.5rem 1rem;
}
.main-banner-buttons {
  color: #7b7b7b;
}
.main-banner-buttons .btn {
  max-width: 300px;
  width: 100%;
  font-size: 30px;
  border-radius: 5px;
  padding: 8px 0;
}
.main-banner .bookstore {
  position: relative;
  width: 100%;
  z-index: 5;
  margin-top: 40%;
}

.book-store-title-image {
  position: absolute;
  left: -45%;
  top: -85%;
  z-index: -1;
}
.book-store-title-image img {
  width: 180px;
}
.main-banner-buttons .note {
  font-size: 20px;
}
.btn-store {
  background-color: #dfa343;
  border-color: #765a28 !important;
  color: #ffffff !important;
}
.btn-subscriptions {
  background-color: #9c1bff;
  border-color: #660193 !important;
  color: #ffffff !important;
}
.btn-subscriptions:hover,
.btn-subscriptions:focus {
  background-color: #a951ec;
}
.splash-container {
  background-color: #fff;
  padding-top: 20px;
}

.bzabc-application {
  color: #454545;
}

.bzabc-application h1 {
  color: #2950da;
}

.app-download {
  background: #f2f7ff;
  border-radius: 25px;
  margin: 2rem auto 4rem auto;
  padding: 0px 10px;
}
.app-download h2 {
  font-family: Gotham Rounded;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  color: #213e86;
}
.app-download .description {
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
}
.app-download .app-download-icons {
  padding-top: 30px;
  padding-bottom: 30px;
}
.app-download .app-download-icons img {
  height: 80px;
  margin-right: 20px;
}
.app-download .app-publisher-icons {
  padding-top: 20px;
  padding-bottom: 20px;
}
.app-download .app-publisher-icons img {
  height: 100px;
}

.learning-platform-background {
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: bottom left;
  padding-bottom: 170px;
}
.platform-pl-bg {
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc/platform_blob.png);
}
.schools-pl-bg {
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc/schools_blob.png);
}
.parents-pl-bg {
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc/parents_blob.png);
}
.publishers-pl-bg {
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc/publishers_blob.png);
}

.platform-video-area {
  position: relative;
  margin-right: 106px;
}
.platform-video-area .platform-video {
  width: 100%;
}

.platform-video-area .play-btn {
  position: absolute;
  width: 58px;
  height: 58px;
  top: calc(50% - 29px);
  left: calc(50% - 29px);
  cursor: pointer;
}

.platform-exp-label {
  font-size: 21px;
  line-height: 25px;
}
.platform-exp-label i {
  width: 24px;
  height: 24px;
  color: #00d1a2;
}

.platform-start .start-btn,
.training-section .start-btn {
  padding: 15px 40px;
  border-radius: 4px;
  font-family: Gotham Rounded;
  font-style: normal;
  color: #fff;
}
.platform-start .start-btn {
  background: #00d1a2;
  font-size: 19px;
  line-height: 24px;
}

.training-section .start-btn {
  font-size: 20px;
  line-height: 25px;
  background: #faa422;
}

.about-us-note {
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  margin-bottom: 120px !important;
}

.bzabc-tech-container {
  padding: 120px 80px 80px 80px;
}
.bzabc-tech-container h1 {
  color: #2744ac;
  font-size: 52px;
  line-height: 62px;
  margin-bottom: 80px;
}
.bzabc-tech-container h1.techspec-title {
  color: #14caca;
  margin-top: 80px;
}
.bzabc-method-list .odd,
.bzabc-spec-list .odd {
  padding-left: 4rem !important;
}
.bzabc-method-list .even,
.bzabc-spec-list .even {
  padding-right: 4rem !important;
}
.bzabc-method-list .method-item img {
  width: 100%;
  border-radius: 8px;
}
.bzabc-method-list .method-item h3 {
  color: #2744ac;
  font-size: 20px;
  line-height: 24px;
}
.bzabc-method-list .method-item p {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #76879f;
  max-width: 450px;
  white-space: pre-wrap;
}

.bzabc-spec-list .spec-item img {
  width: 180px;
}
.bzabc-spec-list .spec-item h3 {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.bzabc-spec-list .spec-item p {
  font-size: 17px;
  line-height: 140%;
  text-align: center;
  color: #76879f;
  max-width: 450px;
  white-space: pre-wrap;
  margin-left: auto;
  margin-right: auto;
}

.training-section {
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/training-for-learner.png);
  background-size: cover;
}
.training-section .training-title {
  font-size: 40px;
  line-height: 140%;
  color: #fff;
}
.training-section .training-description {
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #a0ccff;
}

.applications > div {
  display: inline-block;
  padding-left: 40px;
  text-align: center;
}
.applications > div p {
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: 500;
  margin-top: 2px;
  line-height: 1;
}
.applications > div a {
  color: #ffffff;
  font-size: 11px;
  white-space: nowrap;
}
.p-text {
  color: #7b7b7b;
  font-size: 20px;
  margin: 20px 0 30px;
}
.p-text ul {
  list-style: none;
}
.about-us-note {
  text-align: center;
  color: #1c4062;
  font-size: 20px;
  margin: 0 50px;
}
h1.section-head {
  background-color: #1b3f5f;
  margin: 50px 0 30px;
  padding: 0;
  text-align: center;
  color: #ffffff;
  border-radius: 15px;
  font-size: 50px;
  padding: 10px;
}
section.welcome h5 {
  font-size: 24px;
  margin: 20px 0;
}
section.welcome .btn-link,
section.welcome .btn-link:hover,
section.welcome .btn-link:focus {
  color: #1b3f5f;
  cursor: pointer;
}
section.welcome .btn-link:hover,
section.welcome .btn-link:focus {
  text-decoration: none;
}
section.welcome .image {
  position: relative;
}
section.welcome .image > img {
  width: 100%;
  border: 1px solid #3c6b99;
  border-radius: 19px;
}
section.welcome .image a {
  position: absolute;
  top: calc(50% - 26px);
  left: calc(50% - 32px);
  width: 64px;
}
section.methods h1 {
  margin-top: 30px;
}
section.methods .method,
section.tech-specs .tech-spec {
  margin: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  height: 95%;
  border: 1px solid #3c6b99;
}
section.methods .method h2,
section.tech-specs .tech-spec h2 {
  text-align: center;
  color: #1b3f5f;
  font-size: 48px;
  white-space: nowrap;
  padding: 20px 0;
}
.row.methods-row,
.row.tech-specs-row {
  margin-left: -17px;
  margin-right: -17px;
}
section.methods .method h2 a,
section.tech-specs .tech-spec h2 a {
  color: #1b3f5f;
}
section.methods .method h2 {
  font-size: 42px;
}
section.methods .method img,
section.tech-specs .tech-spec img {
  width: 100%;
}
section.tech-specs .tech-spec img {
  max-width: 178px;
}
section.methods .method .method-description,
section.tech-specs .tech-spec .tech-spec-description {
  color: #1b3f5f;
  padding: 20px;
  text-align: center;
}
section.methods .method .method-description {
  padding: 35px 40px 30px 40px;
  font-size: 18px;
}
.tech-spec .tech-spec-description {
  font-size: 16px;
}
.splash .left-header {
  display: flex;
  justify-content: flex-start;
}
.left-header .fa-home {
  color: white;
  size: 116px;
}
.splash .splash-header {
  background-color: var(--splash-header-bg);
}
.splash .splash-header .links {
  flex: 1;
}
.splash-header a:hover {
  outline: 0;
  color: #ffffff;
  text-shadow: 0 0 1px white;
}
.splash-footer {
  padding: 20px;
  background: #1b3f5e;
  color: #ffffff;
}
.splash-footer .social-btn {
  width: 30px;
  height: 30px;
  line-height: 32px;
  background: #1c5fc1;
  border-radius: 50px;
  text-align: center;
}
.splash-footer .app-store {
  height: 40px;
  margin-left: 60px;
  border-radius: 4px;
}
.splash-footer .links {
  line-height: 40px;
}
.splash-footer a {
  color: #ffffff;
}
.splash-footer .copyright {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  margin-top: 50px;
}
.splash-footer .container {
  align-items: center;
}
.splash-navigation {
  background-color: var(--splash-navbar-bg);
  color: #fff;
}
.splash-navigation .btn {
  color: #fff;
  text-transform: uppercase;
  font-size: 1em;
}
.splash-navigation a:hover {
  outline: 0;
  color: #ffffff;
  text-shadow: 0 0 1px white;
}
.splash-navigation-tools {
  line-height: 1;
}
.header-break {
  margin-top: 0;
  margin-bottom: 30px;
}
.splash-container .splash-footer {
  margin-top: 60px;
}
.splash-footer .footer-container {
  display: flex;
  justify-content: space-between;
}
.splash-footer .footer-container .row {
  width: 100%;
}
.splash-footer a:hover {
  outline: 0;
  color: #ffffff;
  text-shadow: 0 0 1px white;
}
.bookstore-slider {
  background: #f9fafc;
}
.splash-store {
  padding-top: 140px;
  padding-bottom: 80px;
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/svg/bookstore_bg.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 600px;
}

.splash-store header {
  margin-bottom: 20px;
}
.splash-store h2 {
  font-size: 52px;
  line-height: 62px;
  text-align: center;
  color: #2950da;
}
.splash-store .productSection {
  width: 100%;
  border-radius: 15px;
  padding: 20px;
}
.slick-center {
  position: relative;
  z-index: 51;
}
.slick-initialized .slick-slide {
  visibility: hidden;
}
.slick-initialized .slick-slide.slick-active {
  visibility: visible;
}
.slick-initialized .slick-slide .cardItem {
  margin: 0 auto;
  background: #fafaff;
  box-shadow: 0px 6px 30px rgba(19, 30, 37, 0.1);
  border-radius: 8px;
}
.slick-prev::before,
.slick-next::before {
  font-size: 45px;
  color: #d4e7f9 !important;
}
.cardItem .cardTitle a {
  font-size: 16px;
  font-family: Gotham Rounded;
  font-weight: bold;
  line-height: 19px;
  color: #454545;
}

.cardItem .cardDesc {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: #6c9bd1;
}
.verticalCardItem.cardItem {
  min-width: 110px;
  max-width: 170px;
}
.verticalCardItem.cardItem > div {
  box-shadow: none;
  background: #fafaff;
  padding: 12px 16px;
}
.verticalCardItem.cardItem .free-product {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 27px;
  color: #33bb38;
}
.verticalCardItem.cardItem .productPrice .price {
  font-family: Avenir;
  font-size: 20px;
  line-height: 23px;
  color: #4154f9;
}

.slick-track {
  display: flex;
  align-items: center;
}
.slick-center {
  margin: 0 15px;
}
.slick-center .cardItem,
.slick-slide-previous .cardItem {
  min-width: 140px;
  max-width: 276px;
}
.splash-footer .img-logo {
  width: 250px;
}
.left-header .img-logo {
  width: 250px;
}
.gifts.splash-container .container.full-width {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: -60px;
}
/* Responsive Handling */
.splash-tab-content {
  background-color: #ffffff;
  padding: 1rem;
  color: #7b7b7b;
}
.splash-tabs header {
  padding-bottom: 5px;
  margin-bottom: 10px;
  -webkit-box-shadow: -2px 3px 5px -1px rgba(138, 138, 138, 1);
  -moz-box-shadow: -2px 3px 5px -1px rgba(138, 138, 138, 1);
  box-shadow: -2px 3px 5px -1px rgba(138, 138, 138, 1);
  background: #efefef;
}
.splash-tabs header div > span {
  background-color: #4a8dd3;
}
.splash-tabs button {
  width: 25%;
  max-width: 25%;
  min-width: 25%;
  line-height: 1;
  align-items: stretch;
  padding: 0 24px;
}
.splash-tabs button[aria-selected="true"],
.splash-tabs button[aria-selected="true"] span {
  color: #4a8dd3;
}
.splash-tabs button span {
  font-size: 1.5rem;
  padding: 0;
  line-height: 1;
}

.splash-tabs button > span:first-child {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.splash-tabs .platform-overview,
.splash-tabs .schools-teachers,
.splash-tabs .parents-students,
.splash-tabs .publishers {
  padding: 0;
  background-image: url(https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc_icons_full_set.png);
  background-repeat: no-repeat;
  height: 35px;
  width: 50px;
  display: inline-block;
  background-repeat: no-repeat;
}

.splash-tabs button .platform-overview {
  background-position: 0 -344px;
  filter: invert(65%);
}

.splash-tabs button[aria-selected="true"] .platform-overview {
  background-position: 0 -306px;
  filter: invert(0%);
}

.splash-tabs button .schools-teachers {
  background-position: 0 -36px;
  filter: invert(65%);
}

.splash-tabs button[aria-selected="true"] .schools-teachers {
  background-position: 0 0;
  filter: invert(0%);
}

.splash-tabs button .parents-students {
  background-position: 0 -108px;
  filter: invert(65%);
}

.splash-tabs button[aria-selected="true"] .parents-students {
  background-position: 0 -72px;
  filter: invert(0%);
}

.splash-tabs button .publishers {
  background-position: 0 -262px;
  filter: invert(65%);
}

.splash-tabs button[aria-selected="true"] .publishers {
  background-position: 0 -221px;
  filter: invert(0%);
}

.youtube-player-dialog > div > div {
  max-width: 900px;
  width: 900px;
  height: 600px;
  margin: 0px !important;
}
.youtube-player-dialog .youtube-player-content {
  overflow-y: hidden;
  padding: 18px 18px 10px 18px !important;
}
.youtube-player-dialog .youtube-player-content iframe {
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) and (max-width: 1239.98px) {
  .splash .container {
    max-width: 991px;
  }
}
@media (min-width: 767px) {
  .splash-navigation div > a {
    padding: 0;
    display: inline-block;
    background-repeat: no-repeat;
    padding: 2px 5px 2px 5px;
    font-size: 16px;
    margin: 0 5px;
    color: #fff;
  }
  .splash-navigation div .display-7 {
    font-size: 1.4rem !important;
  }
  .footer-links {
    display: none;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1830px;
  }
  .main-banner .container {
    max-width: 90%;
  }
}

@media (max-width: 1440px) {
  .main-banner-buttons .btn {
    font-size: 28px;
  }
  h1.section-head {
    font-size: 44px;
    padding: 10px;
  }
  .splash-store h2 {
    font-size: 40px;
  }
  .splash-points ul li {
    font-size: 30px;
    padding: 0.25rem 1rem;
  }
  section.methods .method h2,
  section.tech-specs .tech-spec h2 {
    font-size: 40px;
  }
  section.methods .method h2 {
    font-size: 36px;
  }
  .book-store-title-image img {
    width: 170px;
  }
  .platform-video-area {
    margin-right: 80px;
  }
  .bzabc-tech-container h1.techspec-title {
    margin-top: 60px;
  }
  .bzabc-tech-container h1 {
    margin-bottom: 60px;
  }
}

@media (max-width: 1279px) {
  .main-banner h1,
  .bzabc-application h1 {
    font-size: 42px;
  }
  .main-banner h3 {
    font-size: 18px;
  }
  .main-banner h2,
  .bzabc-application h2 {
    font-size: 26px;
  }

  .app-download {
    font-size: 14px;
  }
  .main-banner-buttons .btn {
    font-size: 24px;
  }
  .splash-store h2 {
    font-size: 36px;
  }
  .splash-points {
    margin-top: 2rem;
  }
  .splash-points ul li {
    font-size: 26px;
    padding: 0.25rem 1rem;
  }
  h1.section-head {
    font-size: 36px;
    padding: 10px;
  }
  section.methods .method h2,
  section.tech-specs .tech-spec h2 {
    font-size: 36px;
  }
  section.methods .method h2 {
    font-size: 28px;
  }
  .book-store-title-image img {
    width: 160px;
  }
  .platform-video-area {
    margin-right: 70px;
  }
  .about-us-note {
    margin-bottom: 80px !important;
  }
  .learning-platform-background {
    padding-bottom: 100px;
  }
  .bzabc-tech-container {
    padding: 80px 60px 60px 60px;
  }
  .splash-store {
    padding-top: 100px;
    padding-bottom: 60px;
  }
  .splash-header > div .links a {
    margin-left: 16px;
    font-size: 13px;
  }
}

@media (max-width: 1239px) {

  .main-banner .schools-buttons .overview-pdf {
    margin: auto;
  }
}

@media (max-width: 1024px) {
  .main-banner .main-banner-background {
    background-position: center 18%;
  }

  .main-banner .main-banner-background-publisher,
  .main-banner .main-banner-background-schools {
    background-position: top right;
    background-size: auto 170px;
  }

  .main-banner .publisher-buttons {
    padding: initial;
    margin: auto;
  }

  .main-banner .main-banner-background .img-logo {
    width: 200px;
  }
  .main-banner h1 {
    font-size: 36px;
  }
  .main-banner h2 {
    font-size: 16px;
  }

  .splash-tabs button span {
    font-size: 1rem;
  }
  .splash-points {
    margin-top: 3rem;
  }
  .splash-header > div .links a {
    margin-left: 14px;
    font-size: 12px;
  }
  .splash-header > div .links a.btn-login,
  .splash-header .lang-switcher-btn {
    margin-left: 15px !important;
  }
  .splash-points ul li {
    margin-bottom: 10px;
    font-size: 18px;
    padding: 0rem 1rem;
  }
  .main-banner-buttons .btn {
    font-size: 18px;
  }
  .main-banner-buttons .note {
    font-size: 16px;
  }
  section.methods .method h2,
  section.tech-specs .tech-spec h2 {
    font-size: 30px;
  }
  section.methods .method h2 {
    font-size: 24px;
  }
  .splash-navigation .container > a {
    font-size: 16px;
    padding-top: 5px;
  }
  .applications {
    padding-top: 15px;
  }
  .app-download {
    padding: 15px;
  }
  .app-download h3 {
    margin-bottom: 10px;
    font-size: 24px;
  }
  .app-download .description {
    font-size: 18px;
  }
  .gifts .subscriptions-list {
    padding: 50px 0;
  }
  .book-store-title-image img {
    width: 135px;
  }
  .platform-video-area {
    margin-right: 0px;
  }
  .bzabc-method-list .odd,
  .bzabc-spec-list .odd {
    padding-left: 1rem !important;
  }
  .bzabc-method-list .even,
  .bzabc-spec-list .even {
    padding-right: 1rem !important;
  }
  .social-btns {
    padding-left: 32px !important;
  }
  .youtube-player-dialog {
    max-width: 100vw;
  }
  .youtube-player-dialog > div > div {
    width: 75vw;
    height: 50vw;
  }
  .youtube-player-dialog .youtube-player-content {
    padding: 12px 12px 6px 12px !important;
  }
  .links-mobile a {
    font-size: 14px;
    color: #4261ef;
  }
  .left-header .img-logo {
    width: 220px;
  }
  .learning-platform-background {
    padding-bottom: 50px;
  }
  .bzabc-tech-container {
    padding: 60px 40px 40px 40px;
  }
  .platform-exp-label {
    font-size: 19px;
  }
}

@media (max-width: 767.9px) {
  .splash-section {
    padding-top: 60px;
  }

  .splash .container {
    max-width: 100%;
    width: 100%;
  }
  .splash-header a.btn-gift {
    font-size: 15px;
  }

  .left-header .img-logo {
    width: 200px;
  }

  .splash-header a.btn-gift,
  .splash-header .splash-navigation-tools a.btn-login,
  .splash-header .lang-switcher-btn {
    margin-left: 10px !important;
  }
  .main-banner h3 {
    font-size: 18px;
  }
  .main-banner h1,
  .bzabc-application h1 {
    font-size: 26px;
  }
  .main-banner h2,
  .bzabc-application h2,
  .training-section .training-title {
    font-size: 22px;
  }
  .app-download .app-download-icons,
  .app-download .app-publisher-icons {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .bzabc-spec-list .spec-item h3 {
    font-size: 18px;
  }
  .bzabc-method-list .method-item h3 {
    font-size: 18px;
  }
  .main-banner .overview .block {
    padding-left: 0px;
  }
  .main-banner .overview .overview-pdf {
    padding-left: 60px;
  }
  .main-banner .schools-buttons .overview-pdf {
    margin: auto;
    margin-top: inherit;
    margin-bottom: inherit;
  }
  .main-banner .overview .note {
    font-size: 12px;
  }
  .main-banner h6 {
    font-size: 12px;
  }
  .main-banner .bookstore-btn {
    padding: 4px 8px;
  }
  .main-banner .container {
    padding: 0px 20px;
  }
  .splash-store {
    padding-top: 40px;
  }
  .learning-platform-background {
    background-image: none;
  }
  .splash-points {
    margin-top: 2rem;
  }
  .main-banner-buttons .btn {
    font-size: 13px;
    max-width: 160px;
    padding: 5px 0;
  }
  .main-banner-buttons .note {
    text-align: right;
    font-size: 12px;
  }
  .platform-exp-label {
    font-size: 16px;
  }
  .splash-points ul li {
    font-size: 18px;
    padding: 0rem 0.5rem;
  }
  .splash-header .links {
    display: none;
  }
  .splash-header .logo {
    justify-content: center;
  }

  .bzabc-method-list .odd,
  .bzabc-spec-list .odd {
    padding-left: 0rem !important;
  }
  .bzabc-method-list .even,
  .bzabc-spec-list .even {
    padding-right: 0rem !important;
  }

  .splash-tabs button {
    padding: 0 5px;
  }
  .splash-tabs .platform-overview,
  .splash-tabs .parents-students,
  .splash-tabs .schools-teachers,
  .splash-tabs .publishers {
    display: none;
  }
  section.methods .method,
  section.tech-specs .tech-spec {
    margin: 0;
  }
  h1.section-head {
    font-size: 28px;
    padding: 5px;
  }
  .splash-navigation .btn {
    font-size: 14px;
  }
  .splash-navigation .display-7 {
    font-size: 1.3rem !important;
  }
  .footer-links .btn-store,
  .footer-links .btn-subscriptions {
    padding: 10px 0;
  }
  .about-us-note {
    margin: 0;
    font-size: 14px;
  }
  .app-download h3 {
    font-size: 20px;
  }
  .app-download .applications {
    min-width: 190px;
  }
  .app-download > div {
    padding: 0 20px;
  }
  .app-download .description {
    font-size: 14px;
  }
  .applications {
    text-align: center;
  }
  .applications > div {
    padding: 0 8%;
  }
  section.methods .method .method-description {
    padding: 30px 20px;
    font-size: 16px;
  }
  .book-store-title-image img {
    width: 100px;
  }
  .row.methods-row,
  .row.tech-specs-row {
    margin-left: -7px;
    margin-right: -7px;
  }
  .footer-links {
    background-color: #589ee4;
    padding: 15px;
  }
  .splash-footer .img-logo {
    max-width: 100%;
  }
  #footer {
    position: fixed;
    width: 100%;
    left: 0;
  }
  .splash-footer {
    padding: 10px 0;
  }
  .splash-container .splash-footer {
    margin-top: 0;
  }
  .splash-section > .container,
  .splash-container > .container {
    padding-bottom: 125px;
  }
  .splash-section > .container {
    padding-left: 0;
    padding-right: 0;
  }
  .learning-platform-background {
    padding-bottom: 0px;
  }
  .bzabc-tech-container {
    padding: 20px 20px 20px;
  }
  .bzabc-tech-container h1 {
    margin-bottom: 20px;
  }
  .bzabc-tech-container h1.techspec-title {
    margin-top: 40px;
  }
  .main-banner .subscription-btn,
  .platform-start .start-btn,
  .training-section .start-btn {
    padding: 10px 22px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
  }
  .main-banner .bookstore-background {
    background-size: 80px;
  }
}
@media (max-width: 600px) {
  .main-banner .publisher-buttons {
    min-width: 280px;
  }
  .splash-header .links-mobile a {
    display: none !important;
  }
  .splash-header {
    height: auto !important;
  }
}

@media (max-width: 540px) {
  .splash-points ul li {
    font-size: 16px;
  }
  .app-download .applications {
    min-width: 90px;
  }
  .left-header .img-logo {
    width: 140px;
  }
  .youtube-player-dialog > div > div {
    max-width: 100vw;
    width: 90vw;
    height: 60vw;
  }
  .youtube-player-dialog .youtube-player-content {
    padding: 12px 12px 6px 12px !important;
  }
}
@media (min-width: 430px) {
  .splash-points-tools > .download-tool > div {
    display: flex;
    flex-direction: row;
  }
  .splash-points-tools > .download-tool > div a:first-child {
    margin-bottom: 0;
  }
  .splash-points-tools > .download-tool > div img {
    width: 30px;
  }
  .splash-points-tools > .download-tool > h4 {
    font-size: 13px;
  }
}
@media (max-width: 430px) {
  .main-banner .jumbotron-text.multimedia {
    min-width: 300px;
  }
  .splash-section {
    padding-top: 50px;
  }
  .splash-navigation .btn {
    font-size: 14px;
  }
  .main-banner h2 {
    font-size: 15px;
  }
  .app-download h3 {
    font-size: 22px;
  }
  .splash-points ul li {
    font-size: 15px;
  }
  .app-download .container {
    padding: 0;
  }
  .app-download .description {
    font-size: 14px;
  }
  .splash-footer .btn {
    font-size: 14px;
  }
  .footer-links .btn-store,
  .footer-links .btn-subscriptions {
    padding: 10px 0;
    font-size: 18px;
  }
  .youtube-player-dialog .youtube-player-content,
  .youtube-player-dialog .jss84 {
    padding: 8px 8px 4px 8px !important;
  }
  .splash-header a.btn-gift {
    display: none;
  }
  .main-banner h1,
  .bzabc-application h1 {
    font-size: 20px;
  }
  .main-banner h3,
  .bzabc-spec-list .spec-item h3,
  .bzabc-method-list .method-item h3 {
    font-size: 16px;
  }
  .main-banner .main-banner-background {
    padding-top: 170px;
    padding-bottom: 40px;
  }
}
@media (max-width: 360px) {
  .splash-tab {
    font-size: 1.5rem !important;
  }
  .main-banner h1 {
    font-size: 18px;
  }
  .splash-points ul li {
    font-size: 14px;
  }
  .main-banner h2 {
    font-size: 16px;
  }
  .footer-links .btn-store,
  .footer-links .btn-subscriptions {
    padding: 8px 0;
    font-size: 16px;
  }
}
@media (min-width: 320px) and (max-width: 360px) {
  .splash-tab {
    font-size: 0.9rem !important;
  }
  .logo .img-logo {
    width: 80px;
  }
}
