.loaderWrapper {
    position: fixed;
    width:100%;
    height: 100%;
    min-height: 100vh;
    z-index: 55555;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*background: linear-gradient(60deg, rgba(88, 158, 228, 0.3), rgba(50, 88, 125, 0.25));*/
}

.spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

.cube1, .cube2 {
    background-color: #09a7e0;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
    background-color: #e02426;
}

@-webkit-keyframes sk-cubemove {
    25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
    50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
    75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
    100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(0.5);
        -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% {
          transform: translateX(42px) translateY(42px) rotate(-179deg);
          -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
      } 50.1% {
            transform: translateX(42px) translateY(42px) rotate(-180deg);
            -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
        } 75% {
              transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
              -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
          } 100% {
                transform: rotate(-360deg);
                -webkit-transform: rotate(-360deg);
            }
}


.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}
.loader {
    /*margin-left: 180px;*/
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(104, 177, 255, 0.2);
    border-right: 1.1em solid rgba(104, 177, 255, 0.2);
    border-bottom: 1.1em solid rgba(104, 177, 255, 0.2);
    border-left: 1.1em solid #68b1ff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




/* Main styles */
@import url(//fonts.googleapis.com/css?family=Open+Sans:800);

.textLoader .text {
    fill: none;
    stroke-width: 6;
    stroke-linejoin: round;
    stroke-dasharray: 120 280;
    stroke-dashoffset: 0;
    -webkit-animation: stroke 6s infinite linear;
    animation: stroke 6s infinite linear;
}

.textLoader .text:nth-child(3n + 1) {
    stroke: #F5A503;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

 .textLoader .text:nth-child(3n + 2) {
    stroke: #2e8ded;
    -webkit-animation-delay: -4s;
    animation-delay: -4s;
}

 .textLoader .text:nth-child(3n + 3) {
    stroke: #72b3f3;
    -webkit-animation-delay: -6s;
    animation-delay: -6s;
}

@-webkit-keyframes stroke {
    100% {
        stroke-dashoffset: -400;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: -400;
    }
}

.textLoader{
    background-size: .2em 100%;
    font: 8em/1 Roboto;
    font-weight: 700;
    text-transform: uppercase;
    top: 0;
    left: 0;
    position: fixed;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(0, #1B3F5E, #32587d);
}

.textLoader svg {
    position: absolute;
    width: 100%;
    height: 100%;
}