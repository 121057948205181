#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 10px;
  position: fixed;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.22);
}

::-webkit-scrollbar-track {
  background-color: #ededed;
}
button,
[role="button"],
[type="button"],
.btn {
  outline: none !important;
}

textarea {
  resize: none;
}
.mx-auto {
  margin: 0 auto !important;
}
.g-red {
  color: #d2322d !important;
}

.g-green {
  color: #7ac943 !important;
}

.g-orange {
  color: #f79226 !important;
}

.g-blue {
  color: #29a9ff !important;
}

.g-gray {
  color: #ccc !important;
}

.g-metal {
  color: #777 !important;
}

.display-5 {
  font-size: 2rem !important;
}
.display-6 {
  font-size: 1.5rem !important;
}
.display-7 {
  font-size: 1.6rem !important;
}
.display-10 {
  font-size: 1.25rem !important;
}

.App {
  text-align: center;
}

.table th, .table td {
  vertical-align: middle;
}

.table td.actions {
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
}

.row > div {
  margin-left: 15px;
  margin-right: 15px;
}

.row > a {
  margin-top: -50px;
  margin-bottom: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

/* Custom Stylesheet */

.pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

header {
  transition: 0.2s;
}

header.dialogAppBar {
  background-color: #0088cc;
}

.disabled {
  pointer-events: none !important;
  cursor: not-allowed;
  opacity: 0.7;
}

.not-allowed {
  cursor: not-allowed;
}

.form-control-feedback.error {
  color: #f4516c;
}

button [role="progressbar"] {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}

table th .sort-arrow {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: rgba(5, 65, 112, 0.64);
}

.slick-prev::before,
.slick-next::before {
  color: grey !important;
}

.radius-0 {
  border-radius: 0 !important;
}

.btn.btn-info {
  background-color: #36a3f7;
  border-color: #36a3f7;
}

.btn-green {
  background: #40cb67;
}

.grid-tile a,
.grid-tile a:hover {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
}

.m-nav__link-text::first-letter {
  text-transform: uppercase;
}

.progress {
  background-color: #d2322d !important;
  border-radius: 10px;
}


.flex-1 {
  flex: 1;
}

div.row {
  justify-content: center;
  margin-right: 0px;
  margin-left: 0px;
}

body {
  background-color: #f2f3f8;
}

.breadcrumbs a {
  color: #fff;
}

.popperDropMenu {
  z-index: 55;
}

.m-aside-left {
  transition: all 0.2s;
}

.m-nav__item {
  position: relative;
}

div#notFoundPage {
  margin: -30px;
  background-position: center top;
  background-repeat: no-repeat;
}

.store div#notFoundPage {
  margin: 0;
}

.with-divider {
  border-top: 1px solid rgba(128, 128, 128, 0.45);
}

.progress {
  background-color: #bdccd4 !important;
}

.MainModal div[class^="col-"] {
  margin-bottom: 5px;
}

.table thead tr.active th {
  background-color: #f4f3f8;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 600;
  padding: 16px 10px;
  color: #575962;
}
.date-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  font-size: 12px;
}
.btn.red {
  background-color: #ce342c;
  border-color: #ce342c;
}
.btn.blue {
  border-color: #4fa1ea;
  background-color: #4fa1ea;
}
.btn.green {
  background-color: #558436;
  border-color: #558436;
}
.sortable-chosen {
  border: 1px #cccccc dashed;
  padding: 5px 16px;
}
.h-auto {
  height: auto;
}
a:hover {
  color: transparent;
}

@media (max-width: 1367px) {
  .table thead tr.active th {
    padding: 8px 8px;
  }
  .table thead tr.active th {
    padding: 10px 8px;
  }
}

@media (max-width: 1240px) {
  div.loader {
    margin-left: 0;
  }

  div#notFoundPage {
    margin: -20px;
  }
}
